<template>
  <div class="col-12 col-lg-6 c g">
    <br><br>
    <div class="card card-body">
        <div class="col-12 text-center">
            <img :src="require('@/assets/images/time-out.png')" style="width: 100px">
            <h2>
                انتهى اشتراكك في <strong class="text-primary">{{ subscribeinfo(code).name }}</strong>
            </h2>
            <p>
                انتهى في {{ subscribeinfo(code).exdate }}
            </p>
            <br>
            <button class="btn btn-lg btn-success" @click="$router.push('/subscribe?code=' + code)">
                <i class="fa fa-check"></i>
                اشتراك الآن في الخدمة
            </button>
            <br><br>
            <a href="/"><i class="fa fa-reply"></i> عودة للرئيسية</a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            subscribes: [],
            code: window.location.href.split("=")[1]
        }
    },
    created(){
        var g = this;
        var user = localStorage.getItem("user");
        if(user){
        $.post(api + '/user/subscribe/subscribes', {
            jwt: JSON.parse(user).jwt,
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.subscribes = r.response
                }
            }).fail(function(){
                g.loading = false
            })
        }
    },
    methods: {
        subscribeinfo(code){
        var g = this, r = {};
        g.subscribes.forEach(function(a){
            if(a.service == code){
            r = {
                valid: new Date(a?.exdate) > new Date() ? true : false,
                demo: a.demo,
                exdate: a.exdate,
                name: a.title
            }
            }
        })
        return r;
        },
    }
}
</script>

<style>

</style>